.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.selectedComponent {
  background-color: #017afe21;
  /* border: 1px solid #017afe3d !important; */
  border: 4px solid #017afe !important;
  padding: 0;
}

.gridContainerFull-uiflow {
  grid-template-areas: "mainArea properties";
  grid-template-columns: 1fr 376px;
  /* 80px 270px 1fr 220px */
}

.gridContainerleft-uiflow {
  grid-template-areas: "mainArea";
  grid-template-columns: 1fr;
}

.gridContainerRight-uiflow {
  grid-template-areas: "mainArea properties";
  grid-template-columns: 1fr 376px;
  /* 80px 270px 1fr 220px */
}

.gridContainer-uiflow {
  grid-template-areas: "mainArea";
  grid-template-columns: 1fr;
}

input[type="color"] {
  -webkit-appearance: none;
  border: none;
  outline: none;
  width: 20px;
  height: 20px;
}

input[type="color"]::-webkit-color-swatch-wrapper {
  padding: 0;
}

input[type="color"]::-webkit-color-swatch {
  border: none;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

/*
 *  scroll-bar
 */

.scroll-bar::-webkit-scrollbar-track {
  /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); */
  border-radius: 10px;
  /* background-color: #F5F5F5; */
}

.scroll-bar::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  /* background-color: #F5F5F5; */
}

.scroll-bar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3); */
  background-color: #9e9e9e66;
}

/* component border area class*/
/* .fielddrag{
     border: 1.3px dashed #9e9e9e6b;
     margin-bottom: 5px;
 }

 .fielddrag:hover{
     border: 1.3px solid #3f51b575;
     cursor: pointer;
 } */

/* .fielddrag:hover {
  border: 1.3px solid #3f51b575;
  cursor: pointer;
} */

/* component border area class child components*/
.fielddragChild {
  position: relative;
  border: 1.3px dashed #9e9e9e6b;
}

.fielddragChild:hover {
  border: 1.3px solid #3f51b575;
  cursor: pointer;
}

/* Component name shower class */
/* .fielddrag:hover > .componentNameOnOver {
  z-index: 1 !important;
} */

.componentNameOnOver {
  background-color: #3f51b5;
  position: absolute;
  top: -26px;
  z-index: -1;
  padding: 1px 10px;
  left: -1px;
  color: #fff;
}

/* On Over the component drop area */

.mystyle>.componentNameOnOver {
  z-index: 1 !important;
}

/* .dragged-over {
  background-color: #3f51b575 !important;
} */

.border {
  border: 2px solid #007aff !important;
}

#svg {
  position: fixed;
}

.handle {
  fill: dodgerblue;
}

#path {
  fill: none;
  stroke: dodgerblue;
  stroke-width: 6;
}

body {
  margin: 0px;
}

.fullnamecontainer {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  box-sizing: border-box;
}

.fullnameinputbox {
  flex: 1 1 auto;
}

.fullnameinputbox>* {
  box-sizing: border-box;
  width: 100%;
}

.fullnamefirstlast {
  width: max(50px, 10%);
}